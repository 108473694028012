import React from 'react'
import { Link } from 'gatsby'

import '../components/globalStyle.css'
import '../components/product.css'
import '../components/graphics.css'

import Header from '../components/header'
import BackButton from '../components/backButton'



const productGraphics = () => (
  <div className='productGraphicsGroup'>
    <Header />

    <BackButton />

    <div className="product">

      <div className="productText">

        <h2> VARIOUS GRAPHICS</h2>
        <p className="productInfo">
          <i>
            June 2017 - Mar 2019
          </i>
        </p>

        <p>
          I started my career as a visual design intern. As a product designer, I took a few visual side projects. Here are some of them!
        </p>

      </div>

      <div className="graphicsGrid">

        <div className="graphicsContent">
          <figure>
            <img src={require('../img/graphics/infuraShirtZoom.png')} className= "graphicsImg"/>
            <figcaption>infura t-shirt distributed at ETH Berlin 2018</figcaption>
          </figure>
        </div>

        <div className="graphicsContent">
          <figure>
            <img src={require('../img/graphics/infuraShirt2.jpg')} className= "graphicsImg"/>
            <figcaption>proposal for infura t-shirt</figcaption>
          </figure>
        </div>

        <div className="graphicsContent">
          <figure>
            <img src={require('../img/graphics/infuraShirt4.jpg')} className= "graphicsImg"/>
            <figcaption>proposal for infura t-shirt</figcaption>
          </figure>
        </div>



        <div className="graphicsContent">
          <figure>
            <img src={require('../img/graphics/fathomIcon2.svg')} className= "graphicsImg"/>
            <figcaption>icons made for fathom</figcaption>
          </figure>
        </div>

        <div className="graphicsContent">
          <figure>
            <img src={require('../img/graphics/fathomIcon1.svg')} className= "graphicsImg"/>
            <figcaption>icons made for fathom</figcaption>
          </figure>
        </div>

        <div className="graphicsContent">
          <figure>
            <img src={require('../img/graphics/fathomIcon3.svg')} className= "graphicsImg"/>
            <figcaption>icons made for fathom</figcaption>
          </figure>
        </div>

        <div className="graphicsContent">
          <figure>
            <img src={require('../img/graphics/openlaw1.svg')} className= "graphicsImg"/>
            <figcaption>design direction made for OpenLaw</figcaption>
          </figure>
        </div>

        <div className="graphicsContent">
          <figure>
            <img src={require('../img/graphics/openlaw2.svg')} className= "graphicsImg"/>
            <figcaption>design direction made for OpenLaw</figcaption>
          </figure>
        </div>

        <div className="graphicsContent">
          <figure>
            <img src={require('../img/graphics/openlaw3.svg')} className= "graphicsImg"/>
            <figcaption>design direction made for OpenLaw</figcaption>
          </figure>
        </div>



      </div>
    </div>
  </div>
)

export default productGraphics
